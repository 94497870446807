import { FC } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import mealShiftHeroImage from '../../assets/mealshift-delivery/logo.png'
import { Typography } from '@mui/material'
/**
 * MealShift  Application
 */
export const MealShiftDelivery: FC = () => {
  return (
    <ApplicationFrame heroImage={mealShiftHeroImage} backgroundColor="#eae8e8">
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        MealShift Delivery
      </Typography>
    </ApplicationFrame>
  )
}
