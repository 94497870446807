import Backdrop from '@mui/material/Backdrop/Backdrop'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { FC } from 'react'

export interface FullPageLoadingProps {
  loading?: boolean
}

/**
 *
 */
export const FullPageLoading: FC<FullPageLoadingProps> = ({
  loading,
  ...props
}) =>
  loading ? (
    <Backdrop open children={<CircularProgress color="inherit" />} />
  ) : null
