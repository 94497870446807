import {
  Box,
  FormControlLabel,
  Tooltip,
  IconButton,
  Grid,
  Typography,
} from '@mui/material'

import { FC, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { UseQueryResult } from 'react-query'
import IOSSwitch from '../../components/IOSSwitch'

export interface AutoAcceptToggleProps {
  connection: UseQueryResult<any, unknown>
  patchConnectionHandler: (options: any) => void
}

/**
 * Auto Accept Toggle component
 */
export const AutoAcceptToggle: FC<AutoAcceptToggleProps> = ({
  connection,
  patchConnectionHandler,
}) => {
  const [isAutoAcceptEnabled, setIsAutoAcceptEnabled] = useState(
    connection.data?.autoAcceptOnPrint
  )

  /**
   * Handle toggling of the auto-accept feature
   */
  const handleToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsAutoAcceptEnabled(checked)
    patchConnectionHandler({
      autoAcceptOnPrint: checked ? true : false,
    })
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pl: 1,
            }}
          >
            <Typography variant="body1">Auto accept orders</Typography>
            <Tooltip title="When enabled, orders will be automatically accepted once printed via Sunmi Cloud Printer.">
              <IconButton sx={{ pt: 0, pb: 0 }}>
                <InfoIcon fontSize="small" sx={{ p: 0 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          <FormControlLabel
            control={
              <IOSSwitch
                checked={isAutoAcceptEnabled}
                onChange={handleToggleChange}
              />
            }
            label=""
          />
        </Grid>
      </Grid>
    </Box>
  )
}
