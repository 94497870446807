import { FC } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogProps,
} from '@mui/material'

export interface ConfirmationDialogProps extends DialogProps {
  dialogContent: string
  dialogTitle: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const { dialogTitle, dialogContent, onConfirm, onCancel, ...dialogProps } =
    props

  return (
    <Dialog {...dialogProps} fullWidth disableEscapeKeyDown>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
