import {
  FormHelperText,
  Autocomplete,
  TextField,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import { FC, useEffect } from 'react'
import { BaseProps } from './SectionPrinter'
import { UseQueryResult } from 'react-query/types'
import { useClientApiCall } from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'

interface MenuSelectionProps extends BaseProps {
  handleMenuSelect: (e: any, newSelectedItems: any[]) => void
  menuRecords: any
  menus: UseQueryResult<any, unknown>
  sections: any
}

export const MenuSelection: FC<MenuSelectionProps> = ({
  connection,
  handleMenuSelect,
  menus,
  menuRecords,
  sections,
}) => {
  useEffect(() => {
    if (menuRecords.length === 1) {
      handleMenuSelect('', menuRecords)
    }
  }, [])

  return (
    <>
      {menus?.isLoading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            size="small"
            options={menuRecords || []}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option?.name}
            value={
              Object.keys(sections || {})
                .map((id: any) =>
                  menuRecords.find((menu: any) => menu.id === id)
                )
                .filter(Boolean) || []
            }
            onChange={handleMenuSelect}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Menus" />}
          />
          <FormHelperText>
            Select the menu from which orders should be printed.{' '}
          </FormHelperText>
        </>
      )}
    </>
  )
}
export const ItemSelection: FC<any> = ({
  connection,
  handleItemSelect,
  sections,
  menuRecords,
}) => {
  return (
    <Grid container spacing={2}>
      {Object.keys(sections).map((menuId) => {
        const menu: any = menuRecords?.find((menu: any) => menu.id === menuId)
        return (
          <>
            {menu && (
              <>
                {' '}
                <Grid item xs={4}>
                  <Typography variant="subtitle2" fontWeight={500}>
                    {menu?.name}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <ItemAutoComplete
                    connection={connection}
                    handleItemSelect={handleItemSelect}
                    menu={menu}
                    sections={sections}
                  />
                </Grid>
              </>
            )}
          </>
        )
      })}
    </Grid>
  )
}

export const ItemAutoComplete: FC<any> = ({
  connection,
  handleItemSelect,
  menu,
  sections,
}) => {
  const [params] = useSearchParams()
  const session = params.get('session')!

  const categories: UseQueryResult<any, unknown> = useClientApiCall(
    session,
    `menus/${menu.id}/categories`
  )

  const record = categories.data?.data || []

  return (
    <>
      {categories.isLoading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            size="small"
            options={record || []}
            disableCloseOnSelect
            getOptionLabel={(option: any) =>
              option?.name || 'category not found'
            }
            value={
              sections[menu.id]
                ?.map((id: any) =>
                  record.find((category: any) => category.id === id)
                )
                .filter(Boolean) || []
            }
            onChange={(e, selected) => handleItemSelect(menu.id, selected)}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Categories" />
            )}
          />
          <FormHelperText>
            Select the category of items to print (Only the items from the
            selected category will be printed on this printer)
          </FormHelperText>
        </>
      )}
    </>
  )
}
