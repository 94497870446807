import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { FC, useState } from 'react'
import { useClientApiMutation } from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'
import { UseQueryResult } from 'react-query'
import { SectionPrinter } from './SectionPrinter'
import { toast } from 'react-toastify'
import axios from 'axios'

/**
 * Dialog component that aloow hte user to add a pirnter via name, serial number
 * and some other options
 */
export interface AddPrinterDialogProps extends DialogProps {
  connection: UseQueryResult<any, unknown>
  selectedPrinter: any
  menus: UseQueryResult<any, unknown>
}

/**
 * Add Printer Dialog
 */
export const AddPrinterDialog: FC<AddPrinterDialogProps> = ({
  menus,
  ...props
}) => {
  const selectedPrinter = props?.selectedPrinter || null
  const [name, setName] = useState(selectedPrinter?.name ?? 'Cloud Printer')
  const [sn, setSn] = useState(selectedPrinter?.sn ?? '')
  const [status, setStatus] = useState(selectedPrinter?.status ?? 'PLACED')
  const [params] = useSearchParams()
  const session = params.get('session')!
  const addPrinter = useClientApiMutation(session, 'add-printer')
  const updatePrinter = useClientApiMutation(session, 'update-printer', 'PATCH')
  const [printerMode, setPrinterMode] = useState(
    selectedPrinter?.mode ?? 'WHOLE_ORDER'
  ) //ENUM ('WHOLE_ORDER','SPECIFIC_SECTION')
  const [sections, setSections] = useState(selectedPrinter?.sections ?? {})
  const mutatePrinter: any = selectedPrinter ? updatePrinter : addPrinter

  /**
   * Derive a small check to see if the form is valid
   */
  const isFormValid = name.length > 0 && sn.length > 0 && status.length > 0

  const handleMutatePrinter = () => {
    mutatePrinter.mutate(
      {
        name,
        sn: sn.replace(/\s+/g, ''), // Removes all spaces
        status,
        sections,
        mode: printerMode,
      },
      {
        onError: (error: any) => {
          if (axios.isAxiosError(error)) {
            toast.error(error?.response?.data.error)
          } else {
            toast.error(error.message)
          }
        },
        onSuccess: (data: any) => {
          toast.success(data?.message)
          void props.connection.refetch()
          props.onClose?.({}, 'escapeKeyDown')
        },
      }
    )
  }

  return (
    <Dialog
      {...props}
      disableEscapeKeyDown={addPrinter.isLoading}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return
        }
        props.onClose?.(event, reason)
      }}
    >
      <DialogTitle>
        {selectedPrinter ? 'Edit Printer' : 'Add Printer'}
      </DialogTitle>
      <DialogContent>
        {mutatePrinter.isError &&
          mutatePrinter.error?.response?.data?.error && (
            <FormHelperText error>
              {mutatePrinter.error.response?.data.error as any}
            </FormHelperText>
          )}
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <TextField
              placeholder="e.g: NXXXXXXRTXXXX"
              label="Serial Number"
              fullWidth
              size="small"
              value={sn}
              autoFocus
              required
              onChange={(e) => setSn(e.target.value)}
              disabled={selectedPrinter}
            />
            <FormHelperText>
              The serial number of the printer, e.g: NXXXXXXRTXXXX
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="e.g: Kitchen Printer"
              label="Printer Name"
              variant="outlined"
              fullWidth
              size="small"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <FormHelperText>
              A Frendly name for the printer, e.g: Kitchen Printer
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            {/* Dropdown choosing when to print the order */}
            <Select
              fullWidth
              size="small"
              defaultValue={'PLACED'}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="PLACED">PLACED</MenuItem>
              <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
              <MenuItem value="READY">READY</MenuItem>
              <MenuItem value="REJECTED">REJECTED</MenuItem>
              <MenuItem value="CANCELLED">CANCELLED</MenuItem>
              <MenuItem value="COMPLETED">COMPLETED</MenuItem>
            </Select>
            <FormHelperText>
              The order will only be sent to the printer when it reaches this
              status
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <SectionPrinter
              connection={props.connection}
              printerMode={printerMode}
              handlePrinterMode={(e: any) => setPrinterMode(e.target.value)}
              setSections={setSections}
              sections={sections}
              menus={menus}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'escapeKeyDown')}>
          Close
        </Button>
        <Button
          onClick={handleMutatePrinter}
          disabled={isFormValid === false || mutatePrinter?.isLoading}
          sx={{ width: '25%' }}
        >
          {mutatePrinter?.isLoading ? (
            <CircularProgress size={24} />
          ) : selectedPrinter ? (
            'Save Changes'
          ) : (
            'Add Printer'
          )}{' '}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
