import { FC, useState } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import eposNowLogoAsset from '../../assets/epos-now/logo.png'
import {
  useClientApiCall,
  useClientApiMutation,
} from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
  Link,
  IconButton,
  Dialog,
} from '@mui/material'
import { HelpOutline as HelpIcon } from '@mui/icons-material'

import { ApplicationLoadingScreen } from '../../components/ApplicationLoadingScreen'
import { toast } from 'react-toastify'
import { HelpContent } from './HelpContent'
/**
 * Uber Eats Application
 */
export const EPOSNow: FC = () => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')
  const doConnect = useClientApiMutation(session, 'connect')

  /**
   * Derived State
   */
  const isApiKeyConfigured =
    connection.data?.connection?.apiKey &&
    connection.data?.connection?.apiSecret

  /**
   * While we are loading the connection, we will show a loading spinner
   */
  if (connection.isLoading) {
    return <ApplicationLoadingScreen />
  }

  // When the user clicks the connect button, we will call this function
  const onConnect = (apiKey: string, apiSecret: string) => {
    doConnect.mutate(
      { apiKey, apiSecret },
      { onSuccess: () => connection.refetch() }
    )
  }

  /**
   *
   */
  return (
    <ApplicationFrame heroImage={eposNowLogoAsset}>
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        EPOS Now
      </Typography>
      {/* Show the API Key dialog */}
      {!isApiKeyConfigured && <ConfigureApiCredentials onConnect={onConnect} />}

      {/* Show the configured view */}
      {isApiKeyConfigured && <ConfigureIntegration />}
    </ApplicationFrame>
  )
}

export const ConfigureApiCredentials: FC<{
  onConnect: (apiKey: string, apiSecret: string) => void
}> = (props) => {
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const isFormDisabled = !apiKey || !apiSecret
  const [helpOpen, setHelpOpen] = useState(false)
  return (
    <Box pb={2}>
      <Grid container spacing={3} p={2}>
        <Grid item sm={12}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="API Key"
              fullWidth
              value={apiKey}
              onChange={(event) => setApiKey(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              fullWidth
              label="API Secret"
              value={apiSecret}
              onChange={(event) => setApiSecret(event.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.onConnect(apiKey, apiSecret)}
            disabled={isFormDisabled}
          >
            Connect
          </Button>
        </Grid>

        {/* Hyperlink for generating API Key */}
        <Grid item sm={12} textAlign="center">
          <Link href="https://www.eposnowhq.com/" target="_blank">
            Click here to Generate API Key and API secret
          </Link>
          <IconButton onClick={() => setHelpOpen(true)} size="small">
            <HelpIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* Help Dialog */}
      <Dialog open={helpOpen} onClose={() => setHelpOpen(false)} maxWidth="md">
        <HelpContent onClose={() => setHelpOpen(false)} />
      </Dialog>
    </Box>
  )
}
export const ConfigureIntegration: FC = () => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')
  const updateConnection = useClientApiMutation(session, 'connection')
  const [priceSelectionMode, setPriceSelectionMode] = useState(
    connection.data?.connection?.priceSelectionMode ?? 'EatOutPrice'
  )
  const [unPaidTenderType, setUnPaidTenderType] = useState(
    connection.data?.connection?.unPaidTenderType ?? 'CASH'
  )
  const [paidTenderType, setPaidTenderType] = useState(
    connection.data?.connection?.paidTenderType ?? 'CARD'
  )
  const [deliveryFeePLU, setDeliveryFeePLU] = useState(
    connection.data?.connection?.deliveryFeePLU ?? undefined
  )
  const [serviceChargesPLU, setServiceChargesPLU] = useState(
    connection.data?.connection?.serviceChargesPLU ?? undefined
  )
  const [taxPLU, setTaxPLU] = useState(
    connection.data?.connection?.taxPLU ?? undefined
  )
  const [otherChargesPLU, setOtherChargesPLU] = useState(
    connection.data?.connection?.otherChargesPLU ?? undefined
  )
  const [driverTipsPLU, setDriverTipsPLU] = useState(
    connection.data?.connection?.driverTipsPLU ?? undefined
  )
  const [autoAdjustStock, setAutoAdjustStock] = useState<boolean>(
    connection.data?.connection?.autoAdjustStock ?? true
  )
  const [autoPrint, setAutoPrint] = useState<boolean>(
    connection.data?.connection?.autoPrint ?? true
  )
  const [showOnTill, setShowOnTill] = useState<boolean>(
    connection.data?.connection?.showOnTill ?? true
  )

  const handleSave = () => {
    updateConnection.mutate({
      priceSelectionMode,
      unPaidTenderType,
      paidTenderType,
      deliveryFeePLU,
      serviceChargesPLU,
      driverTipsPLU,
      taxPLU,
      otherChargesPLU,
      autoAdjustStock,
      autoPrint,
      showOnTill,
    })
    toast.success('Connection Information Saved Successfully')
  }

  return (
    <Box>
      {/* Settings Form */}

      <Grid container direction="row" spacing={1}>
        <Grid container spacing={3} pr={2}>
          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Price Choice</InputLabel>
              <Select
                label="Price Choice"
                defaultValue={priceSelectionMode}
                value={priceSelectionMode}
                onChange={(e) => setPriceSelectionMode(e.target.value)}
              >
                <MenuItem value="EatOutPrice">EatOutPrice</MenuItem>
                <MenuItem value="SalePrice">SalePrice</MenuItem>
                <MenuItem value="CostPrice">CostPrice</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Un Paid Tender Type</InputLabel>
              <Select
                label="Un Paid Tender Type"
                defaultValue={unPaidTenderType}
                value={unPaidTenderType}
                onChange={(e) => setUnPaidTenderType(e.target.value)}
              >
                {connection.data?.tenderTypes.map((tenderType: any) => (
                  <MenuItem value={tenderType.Id}>{tenderType.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Paid Tender Type</InputLabel>
              <Select
                label="Paid Tender Type"
                defaultValue={paidTenderType}
                value={paidTenderType}
                onChange={(e) => setPaidTenderType(e.target.value)}
              >
                {connection.data?.tenderTypes.map((tenderType: any) => (
                  <MenuItem value={tenderType.Id}>{tenderType.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Delivery Fee PLU"
                value={deliveryFeePLU}
                onChange={(e) => setDeliveryFeePLU(e.target.value)}
                required
              />
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Service Charges PLU"
                onChange={(e) => setServiceChargesPLU(e.target.value)}
                value={serviceChargesPLU}
                required
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Tax PLU"
                onChange={(e) => setTaxPLU(e.target.value)}
                value={taxPLU}
                required
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Other Charges PLU"
                onChange={(e) => setOtherChargesPLU(e.target.value)}
                value={otherChargesPLU}
                required
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Driver Tips PLU"
                onChange={(e) => setDriverTipsPLU(e.target.value)}
                value={driverTipsPLU}
              />
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <FormControlLabel
                labelPlacement="end"
                control={<Switch defaultChecked />}
                label="Auto adjust stock when transaction is created"
                onChange={(e, c) => setAutoAdjustStock(c)}
                checked={autoAdjustStock}
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <FormControlLabel
                labelPlacement="end"
                control={<Switch defaultChecked />}
                label="Auto Print when trasaction is created"
                onChange={(e, c) => setAutoPrint(c)}
                checked={autoPrint}
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl variant="outlined" fullWidth>
              <FormControlLabel
                labelPlacement="end"
                control={<Switch defaultChecked />}
                label="Show on till"
                onChange={(e, c) => setShowOnTill(c)}
                checked={showOnTill}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button type="submit" onClick={handleSave}>
          Submit
        </Button>
      </Grid>
    </Box>
  )
}
