import { FC, useState } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import blackPrinterImage from '../../assets/sumni-cloud-printer/printers-black.jpg'
import {
  useClientApiCall,
  useClientApiMutation,
} from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  CircularProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { ApplicationLoadingScreen } from '../../components/ApplicationLoadingScreen'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AddPrinterDialog } from './AddPrinterDialog'
import { AutoAcceptToggle } from './AutoAcceptToggle'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ConfirmationDialog from '../../components/ConfirmationDialog'

const CommonPaper: FC<any> = ({ children, sx, onClick }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 1.5,
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Paper>
  )
}
/**
 * Uber Eats Application
 */
export const SumniCloudPrinter: FC = () => {
  const [params] = useSearchParams()
  const [addPrinterDialogOpen, setAddPrinterDialogOpen] = useState(false)
  const [selectedPrinter, setSelectedPrinter] = useState<any>(undefined)
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')
  const patchConnection = useClientApiMutation(session, 'connection', 'PATCH')
  const menus = useClientApiCall(session, `menus`)

  const patchConnectionHandler = (options: any) => {
    patchConnection.mutate(options, {
      onSettled: () => connection.refetch(),
    })
  }

  /**
   * While we are loading the connection, we will show a loading spinner
   */
  if (connection.isLoading) {
    return <ApplicationLoadingScreen />
  }

  /**
   *
   */
  return (
    <ApplicationFrame heroImage={blackPrinterImage}>
      {addPrinterDialogOpen && (
        <AddPrinterDialog
          connection={connection}
          selectedPrinter={selectedPrinter}
          open={addPrinterDialogOpen}
          onClose={() => setAddPrinterDialogOpen(false)}
          menus={menus}
        />
      )}
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        Sunmi Cloud Printer
      </Typography>

      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, pl: 3, pr: 4 }}
      >
        {/* First Paper with Button and AddIcon */}
        <CommonPaper onClick={() => setAddPrinterDialogOpen(true)}>
          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',

              width: '100%',
              pt: 0,
              pb: 0,
              //    textTransform: 'none',
            }}
            disableRipple
            onClick={() => {
              setAddPrinterDialogOpen(true)
              setSelectedPrinter(undefined)
            }}
          >
            <Typography variant="body1"> Add Printer</Typography>
            <AddIcon sx={{ ml: 1 }} />
          </Button>
        </CommonPaper>

        {/* Second Paper with AutoAcceptToggle */}
        <CommonPaper>
          <AutoAcceptToggle
            connection={connection}
            patchConnectionHandler={patchConnectionHandler}
          />
        </CommonPaper>

        {/* Third Paper with ConnectedPrinters */}
        <CommonPaper>
          <ConnectedPrinters
            setAddPrinterDialogOpen={setAddPrinterDialogOpen}
            setSelectedPrinter={setSelectedPrinter}
            selectedPrinter={selectedPrinter}
            menus={menus}
          />
        </CommonPaper>
      </Box>
    </ApplicationFrame>
  )
}

export const ConnectedPrinters: FC<any> = ({
  setAddPrinterDialogOpen,
  selectedPrinter,
  setSelectedPrinter,
  menus,
}) => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')
  const testPrinter = useClientApiMutation(session, 'test-printer')
  const removePrinter = useClientApiMutation(session, 'remove-printer')
  const clearPrintJob = useClientApiMutation(session, 'clear-print-job')
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  const [dialogContent, setDialogContent] = useState('')
  const [confirmFn, setConfirmFn] = useState<any>(undefined)

  const handleConfirm = () => {
    confirmFn({ sn: selectedPrinter.sn! })
    setConfirmDialogOpen(false)
  }

  /**
   * Remove Printer Handler
   */
  const removePrinterHandler = (options: { sn: string }) => {
    removePrinter.mutate(options, {
      onSettled: () => {
        setSelectedPrinter(undefined)
        void connection.refetch()
      },
    })
  }

  const clearPrintJobHandler = (options: { sn: string }) => {
    clearPrintJob.mutate(options, {
      onSettled: () => {
        setSelectedPrinter(undefined)
        void connection.refetch()
      },
    })
  }
  return (
    <Box>
      <ConfirmationDialog
        open={confirmDialogOpen}
        dialogTitle="Confirmation"
        dialogContent={dialogContent}
        onConfirm={handleConfirm}
        onCancel={() => setConfirmDialogOpen(false)}
      />
      <Typography variant="h6" gutterBottom>
        Connected Printers
      </Typography>
      {!connection.data?.printers?.length ? (
        <Typography color="textSecondary" fontSize={'0.75rem'}>
          No printers connected. Add a printer to the list.
        </Typography>
      ) : (
        <></>
      )}
      {connection.data?.printers?.map(
        (printer: {
          name: string
          sn: string
          online: boolean
          status: string
          mode: string
          sections: any
        }) => (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {/* Small green / red dot  */}
              <Typography variant="body1">
                <Badge
                  variant="dot"
                  color={printer.online ? 'success' : 'warning'}
                  sx={{ mr: 2 }}
                />
                {printer.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Serial Number */}
              <Typography variant="body2">
                Serial Number: {printer.sn}
              </Typography>
              {/* Status */}
              <Typography variant="body2">Status: {printer.status}</Typography>
              {printer.mode && (
                <Typography variant="body2">Mode: {printer.mode}</Typography>
              )}
              {printer?.sections &&
              Object.keys(printer.sections).length &&
              printer.mode === 'SPECIFIC_SECTION'
                ? Object.keys(printer.sections).map((menuId, index) => {
                    const menu = menus?.data?.data?.find(
                      (menu: any) => menu.id === menuId
                    )
                    return menu ? (
                      <MenuSection
                        menuId={menuId}
                        index={index}
                        menu={menu}
                        sections={printer?.sections || {}}
                      />
                    ) : null
                  })
                : null}
            </AccordionDetails>
            <AccordionActions>
              <Tooltip title="Edit" arrow>
                <Button
                  size="small"
                  onClick={() => {
                    setAddPrinterDialogOpen(true)
                    setSelectedPrinter(printer)
                  }}
                  disabled={
                    selectedPrinter?.sn === printer.sn &&
                    removePrinter.isLoading
                  }
                >
                  <EditOutlinedIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Delete" arrow>
                <Button
                  size="small"
                  onClick={() => {
                    setSelectedPrinter(printer)
                    setDialogContent(
                      'Deleting will remove this printer and all configurations'
                    )
                    setConfirmFn(() => removePrinterHandler)
                    setConfirmDialogOpen(true)
                  }}
                  disabled={
                    selectedPrinter?.sn === printer.sn &&
                    removePrinter.isLoading
                  }
                >
                  {removePrinter.isLoading &&
                  selectedPrinter?.sn === printer.sn ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DeleteOutlineOutlinedIcon />
                  )}
                </Button>
              </Tooltip>

              <Tooltip title="Clear Print Queue" arrow>
                <Button
                  size="small"
                  onClick={() => {
                    setSelectedPrinter(printer)
                    setDialogContent(
                      'On clearing it will clear all the print jobs in the queue, Wont print those things'
                    )
                    setConfirmFn(() => clearPrintJobHandler)
                    setConfirmDialogOpen(true)
                  }}
                  disabled={
                    selectedPrinter?.sn === printer.sn &&
                    clearPrintJob.isLoading
                  }
                >
                  {clearPrintJob.isLoading &&
                  selectedPrinter?.sn === printer.sn ? (
                    <CircularProgress size={24} />
                  ) : (
                    <ClearAllOutlinedIcon />
                  )}
                </Button>
              </Tooltip>

              <Tooltip title="Test Print" arrow>
                <Button
                  size="small"
                  onClick={() => {
                    setSelectedPrinter(printer)
                    testPrinter.mutate(
                      { sn: printer.sn },
                      { onSettled: () => setSelectedPrinter(undefined) }
                    )
                  }}
                  disabled={
                    selectedPrinter?.sn === printer.sn && testPrinter.isLoading
                  }
                >
                  {testPrinter.isLoading &&
                  selectedPrinter?.sn === printer.sn ? (
                    <CircularProgress size={24} />
                  ) : (
                    <ReceiptLongOutlinedIcon />
                  )}
                </Button>
              </Tooltip>
            </AccordionActions>
          </Accordion>
        )
      )}
    </Box>
  )
}

const MenuSection: FC<any> = ({ menuId, menu, sections, index }) => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const categories: any = useClientApiCall(
    session,
    `menus/${menuId}/categories`
  )
  return (
    <Typography variant="body2">
      <Typography key={menuId} variant="body2" component="span" pr={1}>
        {menu.name}:
      </Typography>
      {categories.isLoading ? (
        <CircularProgress size={12} />
      ) : sections[menuId]?.length === 0 ? (
        'All'
      ) : (
        sections[menuId]?.map((catId: string, index: number) => {
          const category = categories?.data?.data?.find(
            (category: any) => category.id === catId
          )

          return (
            category && (
              <>
                <Typography key={category.id} variant="body2" component="span">
                  {category.name}
                </Typography>
                {index < sections[menuId]?.length - 1 && ', '}
              </>
            )
          )
        })
      )}
    </Typography>
  )
}

export default MenuSection
