import { FormHelperText, Grid, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { UseQueryResult } from 'react-query'
import { ItemSelection, MenuSelection } from './MenuSelection'

export interface BaseProps {
  connection: UseQueryResult<any, unknown>
}
export interface SectionPrinterProps extends BaseProps {
  printerMode: 'WHOLE_ORDER' | 'SPECIFIC_SECTION'
  handlePrinterMode: () => void
  setSections: React.Dispatch<React.SetStateAction<{}>>
  sections: any
  menus: any
}

export const SectionPrinter: FC<any> = ({
  connection,
  printerMode,
  handlePrinterMode,
  setSections,
  sections,
  menus,
}) => {
  const menuRecords = menus?.data?.data || []

  const handleMenuSelect = (e: any, newSelectedMenus: any[]) => {
    const newSections = newSelectedMenus.reduce((acc, menu) => {
      acc[menu.id] = sections[menu.id] || []
      return acc
    }, {})
    setSections(newSections)
  }
  const handleItemSelect = (menuId: any, newSelectedItems: any[]) => {
    setSections((prev: any) => ({
      ...prev,
      [menuId]: newSelectedItems.map((i) => i.id),
    }))
  }

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Select
          fullWidth
          size="small"
          defaultValue={'WHOLE_ORDER'}
          value={printerMode}
          onChange={handlePrinterMode}
        >
          <MenuItem value="WHOLE_ORDER">Whole Order</MenuItem>
          <MenuItem value="SPECIFIC_SECTION">Specific Section</MenuItem>
        </Select>
        <FormHelperText>
          Choose how you want the orders to be printed{' '}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        {printerMode === 'SPECIFIC_SECTION' && (
          <MenuSelection
            connection={connection}
            handleMenuSelect={handleMenuSelect}
            menus={menus}
            menuRecords={menuRecords}
            sections={sections}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {printerMode === 'SPECIFIC_SECTION' && Object.keys(sections).length ? (
          <ItemSelection
            connection={connection}
            handleItemSelect={handleItemSelect}
            sections={sections}
            menuRecords={menuRecords}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}
