import {
  Typography,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { VpnKeyOutlined, Close } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// @ts-ignore
import step1 from '../../assets/epos-now/api-token/step1.png'
import step2 from '../../assets/epos-now/api-token/step2.png'
import step3 from '../../assets/epos-now/api-token/step3.png'
import step4 from '../../assets/epos-now/api-token/step4.png'
import step5 from '../../assets/epos-now/api-token/step5.png'
import step6 from '../../assets/epos-now/api-token/step6.png'

export const HelpContent = ({ onClose }: any) => {
  //   const [selectedHeading, setSelectedHeading] = useState(null)
  const helpContent: any = [
    {
      heading: 'How to Generate API Key and API secret',
      steps: [
        {
          title: 'Step 1: Log in to your Epos Now Back Office.',
          content: 'Click on the "Generate API Key and API secret" Key link.',
          image: `${step1}`,
        },
        {
          title: 'Step 2: Click on Setup in the menu',
          image: `${step2}`,
        },
        {
          title:
            'Step 3: In the search bar, type "Integrations" and select it.',
          image: `${step3}`,
        },
        {
          title: 'Step 4: Click on REST API.',
          image: `${step4}`,
        },
        {
          title: 'Step 5: Scroll down to find your API devices.',
          content: 'Find your device and click on Advanced Options',
          image: `${step5}`,
        },
        {
          title:
            'Step 6: You’ll see: Epos Key – Your access key for API calls. Epos Secret – Your password for API calls.',
          image: `${step6}`,
        },
      ],
      icon: <VpnKeyOutlined fontSize="small" />,
    },
  ]
  //   const handleHeadingClick = (heading: any) => {
  //     setSelectedHeading(heading)
  //   }

  return (
    <Typography sx={{ padding: '20px' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="16px"
      >
        <Box display="flex" alignItems="center">
          {/* {selectedHeading && (
            <IconButton onClick={() => setSelectedHeading(null)}>
              <ArrowBack />
            </IconButton>
          )} */}
          <Typography
            variant="h6"
            sx={{ alignContent: 'center', marginLeft: '10px' }}
          >
            Help
          </Typography>
        </Box>
        <IconButton onClick={onClose} color="error">
          <Close />
        </IconButton>
      </Box>
      <>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '15px',
          }}
        >
          <IconButton sx={{ marginRight: '5px', marginBottom: '3px' }}>
            {helpContent[0].icon}
          </IconButton>
          <Typography variant="h5" gutterBottom sx={{ lineHeight: '1' }}>
            {helpContent[0].heading}
          </Typography>
        </Typography>

        <Typography paragraph sx={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
          {helpContent[0].steps.map((step: any, index: any) => (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <b>{step.title}</b>
              </AccordionSummary>
              <AccordionDetails>
                <Typography key={index}>
                  <Typography sx={{ marginBottom: '15px' }}>
                    {step.content}
                  </Typography>
                  {step.image ? (
                    <img
                      src={step.image}
                      alt={`Step ${index + 1} Image`}
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  ) : (
                    ''
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Typography>
      </>
    </Typography>
  )
}
